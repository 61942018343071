import { Country, Product, NewsContentCategory, MarketReportTemplate, SubscriptionTemplate, User, LocationCollection } from "@prisma/client";
import { Option as SelectOption } from "@/components/ui/multiple-select";
import moment from "moment";
import { ExtendedSubscriptionTemplate, ExtendedTeam, ExtendedUser, NewUser } from "@/prisma/types";
import { createHash } from "crypto";
import { NextRequest } from "next/server";

export { cn } from './css-utils'

export function getLocalStorage(key: string, defaultValue: boolean | undefined) {
    const stickyValue = localStorage.getItem(key);

    return stickyValue !== null && stickyValue !== "undefined"
        ? JSON.parse(stickyValue)
        : defaultValue;
}


export function setLocalStorage(key: string, value: boolean | undefined) {
    localStorage.setItem(key, JSON.stringify(value));
}

export const countriesToOptions = (countries: Country[]) => {
    const countryOptions: SelectOption[] = countries?.map((country: Country) => {
        return {
            label: country.displayName,
            value: country.displayName,
        } as SelectOption;
    }) ?? [];

    return countryOptions;
}

export const locationCollectionsToOptions = (locationCollections: LocationCollection[]) => {
    const locationCollectionOptions: SelectOption[] = locationCollections?.map((locationCollection: LocationCollection) => {
        return {
            label: locationCollection.name,
            value: locationCollection.name,
        } as SelectOption;
    }) ?? [];

    return locationCollectionOptions;
}

export const productsToOptions = (products: Product[]) => {
    const productOptions: SelectOption[] = products?.map((product: Product) => {
        return {
            label: product.name,
            value: product.name,
        } as SelectOption;
    }) ?? [];

    return productOptions;
}

export const categoriesToOptions = (categories: NewsContentCategory[]) => {
    const categoryOptions: SelectOption[] = categories?.map((category: NewsContentCategory) => {
        return {
            label: category.name,
            value: category.name,
        } as SelectOption;
    }) ?? [];

    return categoryOptions;
}

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const dateToString = (date: Date) => {
    return moment(date).format('MMM DD, YYYY');
}

export const timeToString = (date: Date) => {
    return moment(date).format('hh:mm A');
}

export const dateAndTimeToString = (date: Date) => {
    return moment(date).format('MMM DD, YYYY hh:mm A');
}

export const dateToCondensedString = (date: Date) => {
    return moment(date).format('DD/MM/YYYY');
}

export const daysSinceDate = (date: Date) => {
    return moment().diff(date, 'days');
}

export const daysUntilDate = (date: Date) => {
    return moment(date).diff(moment(), 'days');
}

export const parseDate = (dateString: string | undefined): Date | undefined => {
    if (!dateString) return undefined;
    return new Date(dateString);
};

export const parseDateWithFormat = (dateString: string | undefined, format: string = "DD/MM/YYYY"): Date | undefined => {
    if (!dateString) return undefined;


    // Parse the date string with the expected format
    const date = moment(dateString, format, true);

    if (!date.isValid()) {
        console.error("Invalid date format");
        return undefined;
    }

    // Return the JavaScript Date object, with time set to 00:00:00
    const parsedDate = new Date(date.year(), date.month(), date.date());

    return parsedDate;
};

export const addMonthsToDate = ({initialDate, months}:{initialDate: Date, months: number}) => {
    // Get today's date, add months, and convert to a JavaScript Date object
    return moment(initialDate).add(months, 'months').toDate();
};

export const addDayToDate = ({initialDate, days}:{initialDate: Date, days: number}) => {
    // Get today's date, add months, and convert to a JavaScript Date object
    return moment(initialDate).add(days, 'days').toDate();
}

export const startOfDay = () => {
    // Get today's date, add months, and convert to a JavaScript Date object
    return moment().utc().startOf("day").toDate();
}

/**
 * Normalize the date to the start of the day in UTC.
 * This function takes the provided date, converts it to the equivalent in UTC, and sets it to midnight.
 *
 * @param date - The date object to normalize.
 * @returns A moment instance set to midnight in UTC.
 */
export function normalizeToUtcMidnight(date: Date): Date {

    const year = date.getFullYear();
    const month = date.getMonth(); // Months are zero-based (0 = January, 11 = December)
    const day = date.getDate();
    
    const dateOnlyUtc = new Date(Date.UTC(year, month, day));
    // console.log("isoString:", dateOnlyUtc.toISOString()); // e.g., 2024-10-25T00:00:00.000Z (UTC time)
    return dateOnlyUtc;
}


/**
 * Format the date for display as if it were in UTC.
 * @param date - The date to format.
 * @param formatStr - The desired display format.
 * @returns A formatted date string.
 */
export function formatFromUtcMidnight(date: Date, formatStr: string): string {
    // Use moment to format the date in UTC time for consistent display
    return moment.utc(date).format(formatStr);
}

export const sortByDateStrings = (rowADateString: string | undefined, rowBDateString: string | undefined) => {
    const dateA = parseDate(rowADateString);
    const dateB = parseDate(rowBDateString);

    if (!dateA || !dateB) return 0;
    return dateA.getTime() - dateB.getTime();
}

export const sortByDate = (rowADate: Date | undefined, rowBDate: Date | undefined) => {
    if (!rowADate || !rowBDate) return 0;
    return rowADate.getTime() - rowBDate.getTime();
}

export const getBadgeColor = (marketReportTemplate: MarketReportTemplate) => {
    switch (marketReportTemplate.interval) {
        case "DAILY":
            return "bg-[#D7BF5C]"
        case "WEEKLY":
            return "bg-[#C695C7]"
        case "MONTHLY":
            return "bg-[#ACCBE7]"
        case "QUARTERLY":
            return "bg-[#ACE7B2]"
        case "YEARLY":
            return "bg-[#E7ACAC]"
        default:
            return "bg-[#D7BF5C]"
    }
}

export const hashEmail = (email: string) => {
    return createHash('md5').update(email.toLowerCase()).digest('hex');
}

export const filterTemplatesByDuration = (subscriptionTemplates: ExtendedSubscriptionTemplate[], duration: number) => {
    return subscriptionTemplates.map((template) => {
        const defaultSubscriptionPrices = template.defaultSubscriptionPrices?.filter((price) => price.duration === duration)
        return {
            ...template,
            defaultSubscriptionPrices: defaultSubscriptionPrices
        }
    }) ?? []
}

export async function verifyApiKey(request: NextRequest): Promise<boolean> {
    const apiKey = "04942607-acac-4448-bf86-d6d2bed5d0cc"; // Get the API key from the environment variable
    const { searchParams } = new URL(request.url); // Extract query params from the URL

    const requestApiKey = searchParams.get('api_key'); // Get the API key from URL parameters

    return requestApiKey === apiKey; // Return true if keys match
}

export const generateTestEmail = (actualEmail: string) => {
    const emailParts = actualEmail.split("@");

    return `cmnavigatortest+${emailParts[0]}@gmail.com`;
}

export const getUserName = (user: User | ExtendedUser | null) => {
    if (!user) return "unknown";  

    // check if names are null and format correctly
    if (user.firstName && user.lastName) {
        return `${user.firstName} ${user.lastName}`
    } else if (user.firstName && !user.lastName) {
        return user.firstName
    } else if (!user.firstName && user.lastName) {
        return user.lastName
    } else {
        return "unknown"
    }
}

export const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
        case "active":
            return "bg-green-500"
        case "canceled":
            return "bg-red-500"
        case "expired":
            return "bg-red-500"
        case "inactive":
            return "bg-yellow-500"
        case "UPCOMING":
            return "bg-blue-500"
        default:
            return "bg-gray-500"
    }
}